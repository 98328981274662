<template>
    <div class="header">
    <b-navbar class="navbar" toggleable="lg" type=dark>
            <router-link  :to="{ name: 'Home' }"><img class="logo" src="img/logo.png" alt="Test"></router-link>

            <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                    <Icon v-if="expanded" icon="chevron-up"></Icon>
                    <Icon v-else icon="chevron-down"></Icon>
                </template>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav class="justify-content-center">
                <b-navbar-nav>
                    <b-nav-item
                        class="mr-5"
                        exact exact-active-class="active"
                        :to="{ name: 'Home' }"
                    >
                        <Icon class="mr-1" icon="home"/>Home
                    </b-nav-item>
                    <b-nav-item
                        class="mr-5"
                        exact exact-active-class="active"
                        :to="{ name: 'About' }"
                    >
                        <Icon class="mr-1" icon="question"/>About
                    </b-nav-item>
                    <b-nav-item
                        class="mr-5"
                        exact exact-active-class="active"
                        :to="{ name: 'Projects' }"
                    >
                       <Icon class="mr-1" icon="cogs"/>Projects
                    </b-nav-item>
                    <b-nav-item
                        class="mr-5"
                        exact exact-active-class="active"
                        :to="{ name: 'Contact' }"
                    >
                        <Icon class="mr-1" icon="mail-bulk"/>Contact
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
    </b-navbar>
    </div>
</template>

<script>


export default {
    name: 'Header',
}
</script>

<style scoped>

nav {
    padding: 0.9rem;
    border-bottom: rgba(255,0,0,0.3) 0.1em solid;
}
nav a {
    font-family: 'futura-pt-medium';
    font-weight: bold;
    font-size: 1rem;
}
.logo {
    height: 3em;
    width: 3em;
    margin-right: 1em;
    transition: border 0.5s
}
.logo:hover {
    border: white solid 1px;
    border-radius: 100px;
} 

.header {
    position: sticky;
    top: 0;
    z-index: 1;
}

.navbar-nav a {
    font-family: 'Bebas Neue' !important;
    font-size: 1.5rem;
    margin-right: 1em;
    border-radius: 0.15rem;
    transition: border-bottom 1s;
}

.navbar-nav a:hover {
    border-bottom: white solid 1px;
    margin-bottom: -1px;
    background: linear-gradient(180deg, rgba(255,255,255,0.1) 55%, rgba(102,102,102,1) 100%);
}
.navbar-nav a.active {
    border-bottom: white solid 1px;
    margin-bottom: -1px;
}
</style>