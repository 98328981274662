import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueTypedJs from 'vue-typed-js'
import router from './router'
import VueMeta from 'vue-meta';
import VueScrollReveal from 'vue-scroll-reveal';
// import JwPagination from 'jw-vue-pagination'
import { faChevronUp, faChevronDown, faHome, faQuestion, faCogs, faMailBulk,} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './scss/index.scss';
Vue.use(BootstrapVue)
Vue.use(VueMeta)
Vue.use(VueTypedJs)
Vue.use(VueScrollReveal)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faHome)
library.add(faQuestion)
library.add(faCogs)
library.add(faMailBulk)
library.add(faLinkedin)
library.add(faGithub)
library.add(faFacebook)
library.add(faInstagram)

Vue.component('Icon', FontAwesomeIcon)
// Vue.component('jw-pagination', JwPagination);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
