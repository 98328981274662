<template>
	<div>
		<div @hover="onHover()" class="title-area" :style="`background-image: url(${require('../../public/img/code.jpg')});background-repeat: no-repeat`">
		<b-container>
			<b-row>
				<b-col xl="7" lg="6" md="0" sm="1"></b-col>
				<b-col xl="5" lg="6" md="12" sm="12">
					<div class="wrapper">
						<div class="title-background">
								<h1>James Hensman</h1>
								<span class="term-font term-green">root@jwhensman.com</span><span class="term-font">:</span><span class="term-font mr-1 term-blue">/home</span><span class="term-font mr-1">$</span><br>
								<vue-typed-js :loop="true" :strings="strings" :typeSpeed="50" :backSpeed="50" :backDelay="400" :startDelay="300">
									<span class="typing term-font"></span>
								</vue-typed-js>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
		</div>
		<hr/>	
		<h2 class="subtitle">Under Construction</h2> <!--Tech change, Values-->
	</div>
</template>
	
<script>
export default{
	name: "Home",
	metaInfo: {
		title: 'James Hensman - Home'
	},
	data() {
		return {
			strings: ["Software Developer", "Websites", "Blockchain", "Cloud", "Contact Now!"],
		}
	},
	methods: {
		onHover() {
			//stuff
		}
	},
	mounted() {
		window.scrollTo(0,0); 
	}
}
</script>	

<style scoped>

.title-area {
	pointer-events: none;
	mask-image: linear-gradient(to bottom, rgba(50,50,50,1), rgba(240,240,240,1) 80%, transparent);
	min-height: 70vh;
	background: #000;
	padding: 1rem;
	z-index: 0;
	position: relative;
	background-position: center;
}

.title-background {
	font-family: 'Bebas Neue' !important;
	float: right;
	padding: 1rem;
	border: 3px solid white;
	border-radius: 0.2em;
	box-shadow: 0.1em 0.1em 18px #444;
	background-color: rgba(0,0,0,0.8);
}

.wrapper:hover {
	width: auto;
	height: auto;
	cursor: pointer;
}

.term-font {
	font-family: 'Azeret Mono' !important;
	font-size: 0.8rem;
}
.term-green {
	color: rgb(71, 240, 19)
}
.term-blue {
 color: rgb(33, 50, 235)
}

</style>