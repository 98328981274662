<template lang="pug">
    .footer
        b-container(fluid="md")
            b-row
                b-col(xl="4" lg="3" md="2" sm="12")
                    router-link(:to="{ name: 'Home' }") Home
                    br
                    router-link(:to="{ name: 'About' }") About
                    br
                    router-link(:to="{ name: 'Projects' }") Projects
                    br
                    router-link(:to="{ name: 'Contact' }") Contact
                    br
                    br
                b-col(xl="4" lg="3" md="3" sm="12")
                    a(href="https://www.linkedin.com/in/jhensman234/ins" target="_blank")
                        Icon(:icon="['fab', 'linkedin']") 
                        |  LinkedIn
                    br
                    a(href="https://github.com/JamesHensman1" target="_blank")
                        Icon(:icon="['fab', 'github']") 
                        |  GitHub
                    br
                    a(href="https://www.facebook.com/James-Hensman-Freelancer-107332095188376" target="_blank")
                        Icon(:icon="['fab', 'facebook']") 
                        |  Facebook
                    br
                    a(href="https://www.instagram.com/jw.hensman/" target="_blank")
                        Icon(:icon="['fab', 'instagram']") 
                        |  Instagram
                    br
                    br
                b-col(xl="4" lg="6" md="7" sm="12")
                    a(href="mailto:jameswhensman@gmail.com") Email: jameswhensman@gmail.com
                    br
                    a(href="https://www.timeanddate.com/worldclock/uk/london" target="_blank") My Time: {{ time }} (GB)
                    br
            br
            b-row.justify-content-center.info-bar
                b-col.text-center(xl="4" lg="3" md="2" sm="12")
                    router-link(:to="{ name: 'Home' }") Privacy Policy
                    br
                b-col.text-center(xl="4" lg="3" md="3" sm="12")
                    router-link(:to="{ name: 'Home' }") Terms &amp; conditions
                    br
                b-col.text-center(xl="4" lg="6" md="7" sm="12")
                    router-link(:to="{ name: 'Home' }") FAQ
                    br
            br
            b-row.justify-content-center  
                .text-center &copy; {{ this.year }} James Hensman. All rights reserved
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            year: 0,
            time: Intl.DateTimeFormat("en-GB", {hour: 'numeric',minute: 'numeric', second: 'numeric'}).format(),
        }
    },
    watch: {
        time() {
            return Date.now()
        }
    },
    methods: {
        getYear() {
            let d = new Date
            this.year = d.getYear() + 1900
        }
    },
  created() {
    this.getYear()
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
    },
}
</script>

<style scoped>

.footer {
    color: #f3f3f3;
    border-top: rgba(255,0,0,0.3) 0.1em solid;
    align-content: center
}
.footer a{
    color: #f3f3f3;
    text-decoration: none;
    transition: color 0.1s;
}

.footer a:hover{
    color: #aaa;
}

.info-bar a{
    color: #ccc;
}
</style>