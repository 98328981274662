<template>
    <vue-hcaptcha 
		sitekey="f5371459-f6be-4588-867d-fab7ad52355d"
		@verify="onVerify"
		theme="dark"
		ref="cap">
	</vue-hcaptcha>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  export default {
    name: "HCpatcha",
		components: {
			VueHcaptcha 
		},
		methods: {
			onVerify(token, eKey) {
				this.$emit("verified", token, eKey);
			},
			refresh() {
				this.$refs.cap.reset();
			}
		}
  };
</script>