<template>
	<div>
    <b-container class="contact" fluid="md">

			<b-row>
				<b-col></b-col>
				<b-col xl="6" l="7" md="10" sm="12" xs="8">
					<h1 class="title">Contact Form</h1>
					<p>Please feel free to ask me any questions, or how I can help you</p>
					<b-form @submit="onSubmit" @reset="onReset"  class="justify-content-center">
						<b-form-group
								id="name"
								label="Name:"
								class="mb-1"
						>
								<b-form-input
										label='name'
										type="text"
										class="darker"
										v-model="form.name"
										placeholder="name"
										minlength="1"
										maxlength="30"
										required
								>
								</b-form-input>
						</b-form-group>
						<b-form-group
								id="email"
								label="Email"
								class="mb-1"
						>
								<b-form-input
										label='email'
										type="email"
										class="darker"
										v-model="form.email"
										placeholder="email"
										minlength="1"
										maxlength="50"
										required
								>
								</b-form-input>
						</b-form-group>
						<b-form-group
								id="subject"
								label="Subject:"
								class="mb-1"
						>
								<b-form-input
										label='subject'
										type="text"
										class="darker"
										v-model="form.subject"
										minlength="1"
										maxlength="80"
										placeholder="subject"
										required
								>
								</b-form-input>
						</b-form-group>
						<b-form-group
								id="message"
								label="Message:"
								class="mb-3"
						>
								<b-form-textarea
										label='message'
										type="text"
										class="darker"
										v-model="form.body"
										placeholder="message  (max 2000 characters)"
										minlength="20"
										maxlength="2000"
										required
								>
								</b-form-textarea>
						</b-form-group>
						<HCaptcha ref="cap" @verified="onVerify"/>
						<b-button :disabled="disableButton" type="submit" variant="dark">Submit</b-button>
					</b-form>
				</b-col>
				<b-col></b-col>
			</b-row>
		</b-container>
	</div>
</template>
	
<script>
import HCaptcha from '../components/HCaptcha'

export default{
	name: "Contact",
	metaInfo: {
		title: 'James Hensman - Contact'
	},
	components : {
		HCaptcha,
	},
	data() {
        return {
            passcode: '',
            disableButton: false,
            form : {
                name:'',
                email: '',
                subject: '',
                body: '',
            },
        }
    },
	methods: {
		onSubmit(event) {
			event.preventDefault()
			alert("Not built")
			this.resertVars()
		},
		onreset(event) {
			event.preventDefault()
			this.resertVars()
		},
		resertVars() {
			this.form.name = ""
			this.form.email = ""
			this.form.subject= ""
			this.form.body = ""
			this.passcode = ""
			this.disableButton = true
			this.refreshCaptcha
		},
    onVerify(token) {
				console.log("called")
        this.disableButton = false;
        this.passcode = token;
      },
      refreshCaptcha() {
        this.$refs.cap.refresh()
      },
	},
	mounted() {
		window.scrollTo(0,0); 
	}
}

</script>	

<style scoped>

.darker {
	background-color: #333333;
	border-color: white;
	color: #f3f3f3;
}

.darker::selection {
	background-color: #444444 ;
}

.title {
	font-family: 'Bebas Neue' !important;
	font-size: 6rem;
	text-align: center;
}

</style>