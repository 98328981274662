<template lang="pug">
	div
		h1.title.mb-5 Past Projects
		h2.subtitle Under Construction
</template>
	
<script>
export default{
	name: "Projects",
	metaInfo: {
		title: 'James Hensman - Projects'
	},
	mounted() {
		window.scrollTo(0,0); 
	}
}
</script>	

<style scoped>
.title {
	font-family: 'Bebas Neue' !important;
	font-size: 6rem;
	text-align: center;
}

</style>