<template>
  <div id="app">
    <Header/>
    <router-view
      :key="$route.fullPath"
      class="main-container"
    />
    <Footer/>    
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
/*Add fonts, Bebus Neue, seconardy font tbc*/

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f3f3f3;
  font-size: 1.15rem;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(30,30,30);
  min-height: 100%;
}

.main-container {
  margin:2rem;
  position: relative;
  min-height: 56vh;
}
</style>
