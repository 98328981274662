<template>
	<div>
		<div class="mb-4">
			<h1 class="title">About Me</h1>
		</div>
		<section>
			<b-row class="mb-5">
				<b-col xl="8" lg="5" md="12" sm="12">
				<div>
					<ul>
					<li>Bespoke websites</li>
					<li>Working with blockchain</li>
					<li>Experience using cloud platforms</li>
					<li>Anything else come to mind? Feel free to ask</li>
					</ul>
				</div>
				<hr/>
				<div>
					<p>My name is James Hensman. I am a freelance developer with a few years of programming experience and am currently at the University of York. </p>
					<p>I enjoy new challenges and opportunities; I am open to taking on out of the box software work. I currently am working on side projects that pique my interest (Check my Github for updates). Working with multiple facets of tech: Backened, Linux, Security, Blockchain, Databases, Functional programming and Frontend, I have gained experience in several areas. As time goes on, I consistently seek to learn new skills.</p>
					<p>I believe in quality work, bringing honesty, effort, and excellence into what I do. </p>
					<hr/>
				</div>
				</b-col>
				<b-col class ="frame" xl="4" lg="7" md="12" sm="12">
					<img class="profile" src="/img/profile.jpeg" alt="portrait">
				</b-col>
			</b-row>
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 200, duration: 800 }">
		<h1 class="mb-4 subtitle">Technical Experience</h1>
		<div class="dark skill-border-top">
			<h2 class="subtitle-2">Backend</h2>


			<b-row class="lang-container justify-content-center" xl="8">
					<b-col v-for="lang in languages" :key="lang" class="d-flex align-items-stretch" xl="2" lg="3" md="4" sm="6" xs="12">
						<b-card
							align="center"
							:img-src="lang.img"
							:tag="lang.name"
							:footer="lang.name"
							class="title-class"
							v-on:click="cardClicked(lang.name)"
						>
						</b-card>
					</b-col>
			</b-row>
		</div>
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 200, duration: 800 }">
		<div class="light">
			<h2 class="subtitle-2">Frontend</h2>
			<b-row class="lang-container justify-content-center" xl="8">
					<b-col v-for="frontend in frontends" :key="frontend" class="d-flex align-items-stretch" xl="2" lg="3" md="4" sm="6" xs="12">
						<b-card
							align="center"
							:img-src="frontend.img"
							:tag="frontend.name"
							:footer="frontend.name"
							class="title-class"
							v-on:click="cardClicked(frontend.name)"
						>
						</b-card>
					</b-col>
			</b-row>
		</div>
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 200, duration: 800 }">
		<div class="dark">
			<h2 class="subtitle-2">Server side Tools</h2>
			<b-row class="lang-container justify-content-center" xl="8">
					<b-col v-for="tool in tools" :key="tool" class="d-flex align-items-stretch" xl="2" lg="3" md="4" sm="6" xs="12">
						<b-card
							align="center"
							:img-src="tool.img"
							:tag="tool.name"
							:footer="tool.name"
							class="title-class"
							v-on:click="cardClicked(tool.name)"
						>
						</b-card>
					</b-col>
			</b-row>
		</div>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 200, duration: 800 }">
		<div class="light">
			<h2 class="subtitle-2">Other</h2>
			<b-row class="lang-container justify-content-center" xl="8">
					<b-col v-for="other in others" :key="other" class="d-flex align-items-stretch" xl="2" lg="3" md="4" sm="6" xs="12">
						<b-card
							align="center"
							:img-src="other.img"
							:tag="other.name"
							:footer="other.name"
							class="title-class"
							v-on:click="cardClicked(other.name)"
						>
						</b-card>
					</b-col>
			</b-row>
		</div>
		</section>
		
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 200, duration: 800 }">
		<div class="dark">
			<h2 class="subtitle-2">Cloud</h2>
			<b-row class="lang-container justify-content-center" xl="8">
					<b-col v-for="cloud in clouds" :key="cloud" class="d-flex align-items-stretch" xl="2" lg="3" md="4" sm="6" xs="12">
						<b-card
							align="center"
							:img-src="cloud.img"
							:tag="cloud.name"
							:footer="cloud.name"
							class="title-class"
							v-on:click="cardClicked(cloud.name)"
						>
						</b-card>
					</b-col>
			</b-row>
		</div>
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 100, duration: 800 }">
		<div class="light">
			<h2 class="subtitle-2">Other Experience</h2><br>
			Google Digital marketing<br>
			Hobbyist projects<br>
			YCC<br>
			Azure Cetifications<br>

		</div>
		</section>
		<section v-scroll-reveal.reveal="{mobile: false, delay: 100, duration: 800 }">
		<div class="skill-border-bottom dark">
			<h1 class="subtitle-2">Working Hours</h1>
			<h4>Flexible work</h4>
			30 Days in 4 months
		</div>
		</section>
	</div>
	
</template>
	
<script>
export default{
	name: "About",
	metaInfo: {
		title: 'James Hensman - About'
	},
	data() {
		return {
			languages: [
				{name: "Python3", img:"/img/python.png"},
				{name: "flask", img:"/img/flask.png"},
				{name:"Java", img:"/img/java.png"},
				{name:"Golang", img:"/img/golang.png"},
				{name:"Shell", img:"/img/terminal.png"},
			],
			frontends: [	
				{name:"Javascript", img:"/img/javascript.png"},
				{name:"html", img:"/img/html.png"},
				{name:"css", img:"/img/css.png"},
				{name:"vuejs", img:"/img/vue.png"},
			],
			tools: [
				{name: "git", img:"/img/git.png"},
				{name:"mysql", img:"/img/mysql.png"},
				{name:"sqlite3", img:"/img/sqlite3.png"},
				{name:"linux", img:"/img/linux.png"},
				{name:"docker", img:"/img/docker.png"},
				{name:"redis", img:"/img/redis.png"},
			],
			others: [
				{name:"Gimp", img:"/img/gimp1.png"},
				{name:"VSCode", img:"/img/vscode2.png"},

			],
			clouds: [
				{name: "Cloudflare", img:"/img/cloudflare.png"},
				{name:"Azure", img:"/img/azure.png"},
			],
		}
	},
	methods: {
		cardClicked(lang) {
			alert(lang);
		}
	},
	mounted() {
		window.scrollTo(0,0); 
	}
}

</script>	

<style scoped>

.top {
	width: 100%;
	border: white 1px solid;
}

.profile {
	width: 100%;
	margin: 0 1em;
	mask-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
	margin: 0 auto;
	border-radius: 0.5rem;
	pointer-events: none;
}

.frame {
	text-align: center;
}

.card {
	padding: 1.4em 1.2em 1.4em 1.2em;
	background: black;
	border: 1px white solid;
	box-shadow: 0.05em 0.05em 18px #fff;
	border-radius: 0.7em;
	width: 100%;
	transition: box-shadow 0.3s;
}

.card:hover {
	cursor: pointer;
	box-shadow: 0.05em 0.05em 18px #744;
}

.title-class {
	font-family: 'Bebas Neue' !important;
}

.lang-container {
	margin: 0.5rem;
}

.dark {
	background-color: #111;
}
.light {
	background-color: rgb(30,30,30);
	border-left: #111 solid 5px;
	border-right: #111 solid 5px;
}


.skill-border-top {
	border: 0;
	border-top-left-radius: 1em;
	border-top-right-radius: 1em;
}

.skill-border-bottom {
	border: 0;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em;
}

h2 {
	font-size: 3rem;
}

div {
	padding: 1em;
}

</style>